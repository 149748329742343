export const CONFIG = {
  clienEmail: "formulario-liftit@formulario-lifters.iam.gserviceaccount.com",
  GOOGLE_PRIVATE_KEY: "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDZRHT4dmTyRBdf\nhAgSlArNUqMrCioanetkbNa0frv783Y++mr6iktZY9z+uar61VBVkximKCeeyaTP\nSPUEBgIE7IrKuYb1BbrwBugfzewjD+yLLrbWXTYYvSrKEyiElWlBHICg9z5wUlnl\nIlCikcWVIjzC+G08rAzvzOdmV9PI7hp81xlSFmdZYCIZutkPEhCF4aUw27lhfGDz\n5AcO+EPuAfkDI+aaLwD2YZOQ+x48gO9jhoF8QhE5HFKuFHkrIBOarFu8uXGhuaVM\ntUWRiYhEQ2tyopK8VFPyLezr6hms1qiR4JCLOQb0j68CnKsBM9OF54sJQ+RXCs1H\ngc7Pnu3LAgMBAAECggEAA4ozW63nNKx6EODTp2CAkXxs9Uaor7BD2sfqP3qGDxW4\nOB0PgPGOnEFECam+0U4h0aOkmBE9e79ykVWcRFdZHhL0InnBjS2x0nLa5HXDM5bo\nh2ghIvH35wiMpH14jTpRBv7ZhUGYY5nmjJTtYMxtJMiFYtjC/6lt0kNPjOp8Rm2Z\nq4pIB1LAG/unfBIcpiOzmJUbjlJa2uiYB0nRA8Xo9SjAI0NwQyzTb2tHFO2kYaW/\n1Q7AqglAReDyjHBFDPJEgLfQ4vFCMU3j3XyMUxAAv6FTH8yNYRVqtIJHHBmebt3c\nAHIQZWAFKr+Fh7Zfr7QHs1V079TMg39HN5Y7LQ+yFQKBgQDz6A0JRC5XgpzmkaRk\nCpBaNpMj20jMHlcJVf6CDxugNEDkx/peRsBAo+//+LK9FoleTFjMaJHcdWfYoRg9\nfTDRTVRGXfajBn94d+5z0BC9N+N3AB3G+wDz4OnC7WnPBdGd5UrgcPWxUGCaSRMA\nAhw8/Kk6Y0H+tg8mPdLy0ZhOFQKBgQDkCkWVNDM1ZGSwlsDgah4melt9mFesaPFx\nYOSHHqoHQymcqb3s3/yJ3xRcSE+80Peis+R/Ygs42V+teyW0eJPIElYf2W6AaG2D\ndZ2WVZ/exaVsFAmuGuoL7I5lH1s73wMC6bjNPTkcaXaUl71b5DxjoRfTZYFe6adu\nkYX7f2MkXwKBgQDiZWK0VzUsxXAeFNIiiiuzkTTC86h+zyq637d9wchCzD3wHi+n\nksShO4pqbs559ZFMllFWazn5CIF78wwtBydpH/450r48VKu9hpUvqtHe0SGyM8lw\nCUy5Euvoo7azExOfHFaEPlXQYGtKysl4GH1dnqj2KWv4ocUuGfTUrvhQgQKBgQCA\n/ap0ho184yftl6jMiFo7q7S664SUWtxQJN2/7MGC0CPmoUF0jxKbrlsnBCROium4\n2AclYwE0DAmJ6QHf/uChKHYdF/HgorFnzgzw78g5zBUReJgtKr4/NnUpkD4cNL7Q\nVAKb3cjDYIiZ7udG5miedo4yntU6KfLyoVBnfZxIAwKBgETYYTzubDVxNZTkqvdt\n9HnL8Itm9UJQA9WUg4Nj02c2Fd5JLsYGldfvRJTARGobJjn6LqstoiSiWbe+xFqE\nYs34bwOHMoLh1r+QRcW2ZYB52VLm7wyRAvLdpJcygyHBWtRi5E8MerGRls0IzNUx\n/qE3LdmzPcXO65wNU1cKKs1R\n-----END PRIVATE KEY-----\n",  
}

export const FORMS_LIFTERS = {
  ...CONFIG,
  spreadsheetId: "1nEU5k0M4DkZ4wwqODHfQuV3iMzDsAS1yOqvwDYGQkq8",
}

export const FORMS_OPERATORS = {
  ...CONFIG,
  spreadsheetId: "1RlVYZAzfSZFv3PUJbF5uFJMcadAtxu6ovTAqd3Q7eXI",
}