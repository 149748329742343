import { GoogleSpreadsheet } from 'google-spreadsheet';
import { CONFIG } from '../constansts/googleSheets'

export const addSpreedSheet = async (spreedSheetId, data, tab = 0, updateRow) => {
  const sheet = new GoogleSpreadsheet(spreedSheetId);

  await sheet.useServiceAccountAuth({
    client_email: CONFIG.clienEmail,
    private_key: CONFIG.GOOGLE_PRIVATE_KEY,
  });

  await sheet.loadInfo(); // loads document properties and worksheets
  const page = sheet.sheetsByIndex[tab];

  if(updateRow){
    const rows = await page.getRows();
    Object.keys(data).map(key => {
      rows[updateRow - 2][key] = data[key]
    })

    const larryRow = await rows[updateRow - 2].save()
    return larryRow
  }
  // use service account creds
  const larryRow = await page.addRow(data);
  return larryRow
}
