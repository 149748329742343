import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Select, Input, InputNumber } from "antd"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { PHONE_LIMITS } from "../../@common/constansts/phoneLimits"
import { poetri as poetriActions } from "../../services/Poetri/PoetriActions"
import { getIp } from "../../@common/utils/getIpApi"
import { useContenfulCountrys } from "../../contenful/querys/Countrys"

const { Option } = Select

const InputPhone = ({
  FormParent,
  formRef,
  prefixDetected,
  classInputGroup,
  onChange,
  onPhoneValid,
  prefixKey = "prefix",
  phoneKey = "phone"
}) => {
  
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const contenfulCountrys = useContenfulCountrys()
  const { countries } = useSelector(state => state.poetri)

  useEffect(() => {
    if (!countries) dispatch(poetriActions.getCountries())
  }, [])

  useEffect(() => {
    if (countries && formRef && prefixDetected) defaultPrefix()
  }, [countries])

  const defaultPrefix = async () => {
    try {
      const browserData = await getIp
      const countrieMatch = contenfulCountrys.find(
        country => country.key === browserData.data.country
        )
      const countrySelected = countries.find(item => item.alpha2code === countrieMatch.key)

      if (countrieMatch && formRef.getFieldValue(prefixKey) !== countrieMatch.key) {
        formRef.setFieldsValue({ [prefixKey]: countrieMatch.key })
        if (onChange)
          onChange({ alpha2code: countrieMatch.key, prefix: countrySelected.callingcodes[0] })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const formatterPhone = value => {
    const prefix = formRef.getFieldValue(prefixKey)
    if (formRef && prefix)
      return value.slice(0, PHONE_LIMITS[prefix].max)

    return value
  }

  const validatorPhoneMin = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue(phoneKey).toString().length >= PHONE_LIMITS[formRef.getFieldValue(prefixKey)].min) 
        return Promise.resolve()

      //No borrar el catch, evita que los errores de consola pongan lenta los selects
      return Promise.reject(t("form.error.phone_min")).catch(e => {});
    },
  })

  const onChanged = (prefixCountry) => {
    const countrieSelected = countries.find(countrie =>
      countrie.alpha2code === prefixCountry
    )
    if(onChange)
      onChange({ prefix: countrieSelected.callingcodes[0], alpha2code: countrieSelected.alpha2code })
  }

  const onChangePhone = (value) => {
    formRef.validateFields([phoneKey])
      .then(() => {
        if(onPhoneValid)
          onPhoneValid(value)
      })
  }

  return (
    <Input.Group className={classInputGroup}>
      <FormParent.Item
        name={prefixKey}
        noStyle
        rules={[{ required: true, message: t("form.error.required") }]}
      >
        <Select
          showSearch
          showArrow={false}
          optionFilterProp="children"
          onSelect={(value) => onChanged(value)}
          filterOption={(input, option) =>
            option.value.toLowerCase().includes(input.toLowerCase()) ||
            option.children.includes(input.toLowerCase())
          }
        >
          {countries?.map(item => (
            <Option key={item.alpha2code} value={item.alpha2code}>
              <img width="30" src={item.flag} alt={item.alpha2code} /> +
              {item.callingcodes[0]}
            </Option>
          ))}
        </Select>
      </FormParent.Item>
      <FormParent.Item
        name={phoneKey}
        rules={[
          { required: true, message: t("form.error.required") },
          validatorPhoneMin
        ]}
      >
        <InputNumber
          type="number"
          placeholder="(000) 000 0000"
          formatter={formatterPhone}
          onChange={onChangePhone}
        />
      </FormParent.Item>
    </Input.Group>
  )
}

InputPhone.propTypes = {
  FormParent: PropTypes.objectOf(PropTypes.any),
  classInputGroup: PropTypes.string,
}

export default InputPhone
